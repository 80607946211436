import { ImagePanorama, VideoPanorama } from 'panolens/build/panolens.module'

class PanoCache {
  constructor () {
    this.cache = {}
  }

  get (media) {
    if (!media || !media.id) {
      console.log('[PanoCache] Media doesn\'t exist.')
    }

    const isVideo = media.id.split('.')[1] === 'mp4'

    if (!this.cache[media.id] || isVideo) {
      let panorama = null

      if (isVideo) {
        panorama = new VideoPanorama('/media/' + media.id, { autoplay: false, muted: true, playsinline: true, loop: false })
      } else {
        panorama = new ImagePanorama('/media/' + media.id)
      }

      panorama.addEventListener('progress', event => {
        this.onPanoProgress(media.id, event)
      })

      this.cache[media.id] = { panorama, isLoaded: false }
      this.cache[media.id].loading = new Promise((resolve) => {
        this.cache[media.id]._resolver = resolve
      })
    }

    return this.cache[media.id]
  }

  onPanoProgress (id, event) {
    const percentage = event.progress.loaded / event.progress.total * 100
    if (percentage >= 100) {
      this.cache[id].isLoaded = true
      this.cache[id]._resolver(this.cache[id].panorama)
    }
  }
}

export default new PanoCache()
